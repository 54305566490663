<template>
    <div class="col-lg-4 col-md-4 mb-5">
        <div class="card hover-translate-y-n10 hover-shadow-lg border">
            <card-header :lender-or-card="card" />
            <card-image :image="card.data.card_image" width="200" class="py-5" />

            <div class="card-body py-4 bg-light fs-6">
                <table-row :title="$translate('yearly_cost', 'Årsavgift')" :value="model.yearlyCostString" />
                <table-row :title="$translate('max_credit', 'Maxkredit')" :value="model.getMaxCreditString()" />
                <table-row :title="$translate('interest', 'Ränta')" :value="model.interestString" />
                <table-row 
                    :title="$translate('interest_free_days', 'Räntefria dagar')" 
                    :value="model.getInterestFreeDaysString($translate('amount_of_days', '{days} dagar'))"
                />
            </div>

            <card-links :lender-or-card="card" :position="position" />
        </div>
    </div>
</template>

<script>
import CardHeader from './CardHeader.vue';
import CardImage from './CardImage.vue';
import CardLinks from './CardLinks.vue';
import TableRow from './TableRow.vue';
export default {
    components: {
        CardHeader,
        CardImage,
        TableRow,
        CardLinks
    },
    props: {
        cardId: {
            type: String,
            required: true
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.cardId];
        },
        model() {
            return new this.$models.CreditCard(this.card);
        }
    }
};
</script>