<template>
    <div class="container content-space-1 d-flex justify-content-center">
        <div class="w-100 w-lg-75">
            <div class="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center">
                <div>
                    <h2 class=" display-6 mb-0">
                        {{ $translate('user_ratings_header', 'Användarrecensioner') }}
                    </h2>
                    <p v-if="ratings.count === 0">
                        {{ 
                            $translate('user_ratings_no_reviews', 'Det finns inga recensioner av {title}')
                                .replace('{title}', document.data.title)
                        }}
                    </p>
                    <p v-else class="lead">
                        {{ 
                            $translate('user_ratings_average_rating', '{rating} i snittbetyg av {count} användare')
                                .replace('{rating}', ratings.average_score.toFixed(1))
                                .replace('{count}', ratings.count)
                        }}
                    </p>
                </div>
                <button class="btn btn-dark" @click="scrollToRatingForm">
                    {{ $translate('user_ratings_button_text', 'Lämna betyg') }} <i class="bi bi-star-half fs-4" />
                </button>
            </div>
            <div v-for="(userReview, index) in ratings.data" :key="index" class="mt-3 mb-3">
                <div class="card card-body">
                    <span class="h5 mb-1 text-capitalize">{{ userReview.rater_name }}</span>
                    <p class="card-text small m-0"> 
                        {{ $format.date(userReview.posted_at, 'DATE_FULL') }}
                    </p>
                    <div class="d-flex gap-1 my-2">
                        <i 
                            v-for="(star, starIndex) in userReview.rating_score" 
                            :key="starIndex" 
                            class="bi bi-star-fill text-warning" 
                        /><i 
                            v-for="(star, starIndex) in maxStars - userReview.rating_score" 
                            :key="starIndex" 
                            class="bi bi-star text-warning" 
                        />
                    </div>
                    <div 
                        class="card-text text-dark mb-auto rich-text" 
                        v-html="$format.comment(userReview.rating_body)" 
                    />
                    
                    <div v-if="userReview.reply" class="card bg-soft-primary p-3 mt-3">
                        <span class="h5 mb-1 text-capitalize">
                            {{ userReview.reply.author_name }}
                        </span>
                        <p class="card-text small mb-2">
                            {{ $format.date(userReview.reply.posted_at, 'DATE_FULL') }}
                        </p>
                        <div 
                            class="card-text text-dark rich-text" 
                            v-html="$format.comment(userReview.reply.comment_body)"
                        />
                    </div>
                </div>
            </div>
            <div v-if="success" ref="formSent" class="border-5 p-4 mt-8 rounded border-start border-success bg-dark text-center">
                <i class="text-success bi bi-check-lg me-2 fs-3" />
                <span class="fw-bold text-white h4">
                    {{ successMessage }} 
                </span>     
            </div>
            <div v-else ref="ratingForm" class="container content-space-2 bg-soft-primary mb-3 mb-md-9 mt-3 mt-md-9 rounded">
                <div class="mx-auto" style="max-width: 35rem;">
                    <div class="card card-body px-3 px-md-5 py-5 ">
                        <div class="text-center mb-5 mb-md-7">
                            <h3 class="m-0 h2">
                                {{ 
                                    $translate('user_ratings_form_header', 'Vad tycker du om {title}?')
                                        .replace('{title}', document.data.title) 
                                }}
                            </h3>
                        </div>
                        <div class="row gx-3">
                            <div class="col-sm-6">
                                <div class="mb-3">
                                    <label class="form-label" for="name">{{ $translate('user_ratings_form_name', 'Namn') }}</label>
                                    <input 
                                        id="name" 
                                        ref="focusInput"
                                        v-model="name"
                                        type="text" 
                                        class="form-control" 
                                        :placeholder="$translate('user_ratings_form_name_placeholder', 'John Doe')"
                                        required
                                    >
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="mb-3">
                                    <label class="form-label" for="email">{{ $translate('user_ratings_form_email', 'Email') }}</label>
                                    <input 
                                        id="email" 
                                        v-model="email"
                                        type="email" 
                                        class="form-control" 
                                        :placeholder="$translate('user_ratings_form_email_placeholder', 'john.doe@gmail.com')"
                                        required
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="rate">{{ $translate('user_ratings_form_rating', 'Betyg') }}</label>
                            <div class="rating text-warning fs-3 form-control">
                                <span 
                                    v-for="(star, starIndex) in maxStars"
                                    :key="starIndex"
                                    class="bi" 
                                    role="button"
                                    :class="[{'bi-star-fill': starIndex < starRating}, {'bi-star': starIndex >= starRating}]"
                                    @click.prevent="rating = starIndex + 1;"
                                    @mouseenter="hoverStarRating = starIndex + 1;"
                                    @mouseleave="hoverStarRating = null;"
                                />
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="review">{{ $translate('user_ratings_form_review', 'Recension') }}</label>
                            <textarea 
                                id="review" 
                                v-model="review"
                                class="form-control" 
                                :placeholder="$translate('user_ratings_form_review_placeholder', 'Din recension ...')" 
                                rows="4" 
                                required
                            />
                        </div>
                        <div v-if="error" class="text-center mb-3 text-danger fw-bold">
                            <span class="bi bi-exclamation-triangle-fill me-2" />
                            {{ errorMessage }}
                        </div>
                        <button 
                            class="w-100 btn btn-primary btn-lg" 
                            @click.prevent="submit"
                        >
                            {{ $translate('user_ratings_form_button_text', 'Skicka in') }} <i class="bi bi-send ms-2" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        document: {    
            type: Object,
            required: true
        }
    },
    data() {
        return {
            hoverStarRating: null,
            maxStars: 5,

            enable: true,
            error: false,
            success: false,
            errorMessage: this.$translate('user_ratings_form_error_message', 'Kolla så att alla fält är korrekt i fyllda!'),
            successMessage: this.$translate('user_ratings_form_success_message', 'Tack för ditt meddelande!'),

            email: '',
            name: null,
            review: null,
            rating: null,
        };
    },
    computed: {
        starRating() {
            if (this.hoverStarRating !== null) {
                return this.hoverStarRating;
            }
            return this.rating;
        },
        payload() {
            return {
                source_id: process.env.CORE_SOURCE_ID,
                brand_id: this.document.data.core_api.core_data.core_id,
                rating_body: this.review,
                rating_score: this.rating,
                rater_email: this.email,
                rater_name: this.name,
            };
        },
        ratings() {
            return this.document.data.core_api.ratings;
        }
    },
    methods: {
        scrollToRatingForm() {
            const el = this.$refs.ratingForm;
            if (el) { 
                const elFocus = this.$refs.focusInput.focus({preventScroll: true});
                return el.scrollIntoView({behavior: 'smooth', block: 'center' }), elFocus;
            }
        },
        submit() {
            const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            if (! this.enable) {
                return;
            }

            if (
                ! this.email.match(validEmailRegex) ||
                this.name === null ||
                this.review === null ||
                this.rating === null
            ) {
                this.error = true;
                return;
            }
            
            this.enable = false;
            this.$axios
                .post('https://api.core.compary.com/api/v0/ratings', this.payload)
                .catch((e) => {
                    this.error = true;
                    this.errorMessage = e.errorMessage;
                })
                .then(() => {
                    this.error = false,
                    this.enable = true;
                    this.success = true;


                    const el = this.$refs.formSent;
                    return el.scrollIntoView({block: 'center', behavior: 'smooth' });
                });
        }
    }
};
</script>