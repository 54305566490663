<template>
    <!-- TODO: bad key on preamble_effective_interest -->
    <!-- add class "col-lg-4"? see CreditCard.vue and CorporateLenderCard.vue -->
    <!-- Can this be made into a component? See similarities with components above -->
    <div class="col-md-4 mb-5">
        <div class="card hover-translate-y-n10 hover-shadow-lg border">
            <card-header :lender-or-card="lender" />
            <card-image :image="lender.data.logo" width="150" />

            <div class="card-body p-3 bg-light fs-6">
                <table-row 
                    :title="$translate('loan_amount', 'Belopp')" 
                    :value="model.amountString" 
                    :tooltip="! model.hasExtraLoanAmountToExistingCustomers 
                        ? null 
                        : model.getMaxAmountToExistingCustomersString($translate('max_amount_to_existing_customers', 'Upp till {amount} för existerande kunder'))"
                />
                <table-row 
                    v-if="!$isSpain()"
                    :title="$translate('loan_duration', 'Löptid')" 
                    :value="model.getDurationString($translate('month_short', 'mån'), $translate('year_short', 'år'))"
                />
                <table-row 
                    v-else
                    :title="$translate('loan_duration', 'Löptid')" 
                    :value="$getSpanishLoanDuration(model, $translate('month_short', 'mån'), $translate('year_short', 'år'))"
                />
                <table-row 
                    v-if="!($isSpain() && model.isBroker)"
                    :title="$translate('preamble_effective_interest', 'Effektiv ränta')" 
                    :value="model.effectiveInterestString"
                    :tooltip="! model.hasFirstTimeOffer 
                        ? null 
                        : model.getFirstTimeOfferString(
                            $translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                            $translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                        )"
                />
                <template v-if="$isSweden()">
                    <table-row
                        v-if="model.isBroker" 
                        :title="$translate('connected_banks', 'Anslutna banker')" 
                        :value="model.connectedBanks" 
                    />
                    <table-row 
                        v-else-if="model.maxAmount <= 50000 || model.isHighCostCredit" 
                        :title="$translate('direct_pay_out', 'Direktutbetalning')" 
                        :value="model.hasDirectPayments24Hours ? $translate('around_the_clock', 'Dygnet runt') : 
                            model.hasDirectPayments ? $translate('direct', 'Direkt') :
                            $translate('no', 'Nej')" 
                    />
                    <table-row 
                        v-else 
                        :title="$translate('arrangement_fee', 'Uppläggningsavgift')" 
                        :value="model.setupFeeString" 
                    />
                    <table-row 
                        :title="$translate('without_uc', 'Utan UC')" 
                        :value="$formatBoolean(!model.takesUC)"
                        :tooltip="$translate('uses', 'Använder') + ' ' + $getCreditCheckString(model.creditCheck)"
                    />
                    <table-row 
                        :title="$translate('payment_remarks', 'Betalningsanmärkning')" 
                        :value="model.acceptsPaymentRemarks ? $translate('accepted', 'Accepteras') : $translate('not_accepted', 'Accepteras inte')"
                        :tooltip="model.acceptsPaymentRemarks && model.paymentRemarksMaxCount ? `Max ${model.paymentRemarksMaxCount} st` : null" 
                    />
                </template>
                <template v-else-if="$isNorway()">
                    <table-row title="Bank-ID" :value="$formatBoolean(model.hasBankId)" />
                    <table-row :title="$translate('payment_remarks', 'Betalningsanmärkning')" :value="model.acceptsPaymentRemarks ? $translate('accepted', 'Accepteras') : $translate('not_accepted', 'Accepteras inte')" />
                </template>
                <template v-else-if="$isEstonia()">
                    <!-- TODO: Estonia setup smart id? Have more stuff? -->
                    <!-- <table-row title="Smart ID" value="Ja" /> -->
                    <table-row :title="$translate('arrangement_fee', 'Uppläggningsavgift')" :value="model.getEstonianSetupFeeString()" />
                </template>
                <template v-else-if="$isSpain()">
                    <table-row 
                        v-if="model.isBroker"
                        :title="$translate('preamble_effective_interest', 'Effektiv ränta')" 
                        :value="model.effectiveInterestString"
                        :tooltip="$translate('spain_interest_tooltip_on_broker')"
                    />
                    <table-row 
                        :title="$translate('spain_loan_type', 'Loan type')" 
                        :value="model.isBroker ? $translate('spain_loan_type_broker', 'Broker') : $translate('spain_loan_type_lender', 'Lender')"
                    />
                    <table-row :title="$translate('no_salary', 'Utan inkomst från lön')" :value="$formatBoolean(model.acceptsNoSalary)" />
                    <table-row :title="$translate('with_asnef', 'With ASNEF')" :value="$formatBoolean(model.acceptsASNEF)" />
                </template>
                <table-row 
                    :title="$translate('min_age', 'Åldersgräns')" 
                    :value="model.minAge + ' ' + $translate('years', 'år')"
                />
            </div>

            <card-links :lender-or-card="lender" :position="position" />
            <card-information 
                :compliance-example="model.complianceExample" 
                :is-high-cost-credit="model.isHighCostCredit"
                :lender="lender"
            />
        </div>
    </div>
</template>
<script>
import CardHeader from './CardHeader.vue';
import CardImage from './CardImage.vue';
import TableRow from './TableRow.vue';
import CardLinks from './CardLinks.vue';
import CardInformation from './CardInformation.vue';
export default {
    components: {
        CardHeader,
        CardImage,
        TableRow,
        CardLinks,
        CardInformation
    },
    props: {
        lenderId: {
            type: String,
            required: true
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.lenderId];
        },
        model() {
            return new this.$models.Loan(this.lender);
        }
    },
};
</script>