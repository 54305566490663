<template>
    <div v-if="item" class="p-4 rounded shadow-lg">
        <nuxt-link :to="$prismic.linkResolver(item)" class="d-flex justify-content-center mb-3">
            <prismic-image
                :img="item.data[type.imgKey]"
                w="200" 
                class="w-100 max-wpx-200"
            />
        </nuxt-link>

        <h2>{{ $prismic.asText(header) }}</h2>

        <div class="text-dark pb-3">
            <div v-for="(data, index) in tableData" :key="index" class="d-flex justify-content-between border-bottom py-2">
                <span>{{ data.title }}</span>
                <span class="fw-bold">{{ data.value }}</span>
            </div>
        </div>

        <p>{{ $prismic.asText(text) }}</p>

        <out-link 
            class="btn btn btn-primary w-100 fw-bold" 
            :document="item" 
            placement="banner" 
        />        

        <nuxt-link 
            v-if="includeLoanGuideLink && $store.state.settings.loan_wizard_page" 
            class="btn btn-white fw-bold w-100 text-primary-hover mt-2" 
            :to="$prismic.linkResolver($store.state.settings.loan_wizard_page)"
        >
            <span>{{ $translate('cta_loanguide', 'Få skräddarsydda låneförslag') }}</span>
        </nuxt-link>
    </div> 
</template>

<script>
export default {
    props: {
        header: {
            type: Array,
            required: true
        },
        text: {
            type: [Array, Object],
            required: true
        },
        featuredItem: {
            type: Object,
            required: true
        },
        type: {
            type: Object,
            required: true
        },
        includeLoanGuideLink: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        item() {
            return this.$store.state[this.type.storeKey][this.featuredItem.id];
        },
        model() {
            return new this.$models[this.type.modelKey](this.item);
        },
        tableData() {
            if (this.featuredItem.type === 'lender') {
                return this.lenderData;
            }
            if (this.featuredItem.type === 'lender_corporate') {
                return this.corporateLenderData;
            }
            if (this.featuredItem.type === 'credit_card') {
                return this.creditCardData;
            }
            return [];
        },
        lenderData() {
            return [
                {
                    title: this.$translate('loan_amount', 'Belopp'),
                    value: this.model.amountString
                },
                {
                    title: this.$translate('loan_duration', 'Löptid'),
                    value: this.model.getDurationString(this.$translate('month_short', 'mån'), this.$translate('year_short', 'år'))
                },
                {
                    title: this.$translate('preamble_effective_interest', 'Effektiv ränta'),
                    value: this.model.effectiveInterestString
                },
            ];
        },
        corporateLenderData() {
            return [
                {
                    title: this.$translate('loan_amount_lowest', 'Lånebelopp, lägsta'),
                    value: this.model.minAmountString
                },
                {
                    title: this.$translate('loan_amount_highest', 'Lånebelopp, högsta'),
                    value: this.model.maxAmountString
                },
                {
                    title: this.$translate('loan_duration', 'Löptid'),
                    value: this.model.getDurationString(this.$translate('month_short', 'mån'), this.$translate('year_short', 'år'))
                },
            ];
        },
        creditCardData() {
            return [
                {
                    title: this.$translate('max_credit', 'Maxkredit'),
                    value: this.model.getMaxCreditString()
                },
                {
                    title: this.$translate('preamble_effective_interest', 'Effektiv ränta'),
                    value: this.model.effectiveInterestString
                },
                {
                    title: this.$translate('yearly_cost', 'Årsavgift'),
                    value: this.model.yearlyCostString
                },
            ];
        }
    },
};
</script>