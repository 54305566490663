var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-7 mb-5"},[_c('h2',{staticClass:"text-primary display-6 mb-5"},[_vm._v("\n            "+_vm._s(_vm.bestMatchHeader(_vm.lender.data.title))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$prismic.asText(_vm.lender.data.preamble)))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-5 mb-5"},[_c('div',{staticClass:"card rounded position-relative bg-white shadow border"},[_c('div',{staticClass:"card-header py-1 ps-3 rounded rounded-bottom-0 pe-2 bg-dark w-100"},[_c('nuxt-link',{staticClass:"text-white fs-6 text-decoration-underline",attrs:{"to":_vm.$prismic.linkResolver(_vm.lender)}},[_vm._v("\n                    "+_vm._s(_vm.lender.data.title)+"\n                ")])],1),_vm._v(" "),_c('img',{staticClass:"position-absolute top-0 start-100 translate-middle border border-5 border-light avatar avatar-sm avatar-circle",attrs:{"src":"https://htmlstream.com/preview/front-v4.2/html/assets/svg/illustrations/top-vendor.svg"}}),_vm._v(" "),_c('div',{staticClass:"align-self-center card-body"},[_c('prismic-image',{staticClass:"object-fit-contain",attrs:{"img":_vm.lender.data.logo,"width":"125"}})],1),_vm._v(" "),_c('div',{staticClass:"py-3 fs-6 card-body bg-light"},[_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('loan_amount', 'Belopp'),"value":_vm.model.amountString,"tooltip":! _vm.model.hasExtraLoanAmountToExistingCustomers ?
                        null :
                        _vm.model.getMaxAmountToExistingCustomersString(_vm.$translate('max_amount_to_existing_customers', 'Upp till {amount} för existerande kunder'))}}),_vm._v(" "),(!_vm.$isSpain())?_c('table-row',{staticClass:"border-1 ",attrs:{"title":_vm.$translate('loan_duration', 'Löptid'),"value":_vm.model.getDurationString(_vm.$translate('month_short', 'mån'), _vm.$translate('year_short', 'år'))}}):_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('loan_duration', 'Löptid'),"value":_vm.$getSpanishLoanDuration(_vm.model, _vm.$translate('month_short', 'mån'), _vm.$translate('year_short', 'år'))}}),_vm._v(" "),(!(_vm.$isSpain() && _vm.model.isBroker))?_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('preamble_effective_interest', 'Effektiv ränta'),"value":_vm.model.effectiveInterestString,"tooltip":! _vm.model.hasFirstTimeOffer 
                        ? null 
                        : _vm.model.getFirstTimeOfferString(
                            _vm.$translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                            _vm.$translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                        )}}):_vm._e(),_vm._v(" "),(_vm.$isSweden())?[(_vm.model.isBroker)?_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('connected_banks', 'Anslutna banker'),"value":_vm.model.connectedBanks}}):(_vm.model.maxAmount <= 50000 || _vm.model.isHighCostCredit)?_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('direct_pay_out', 'Direktutbetalning'),"value":_vm.model.hasDirectPayments24Hours ? _vm.$translate('around_the_clock', 'Dygnet runt') : 
                            _vm.model.hasDirectPayments ? _vm.$translate('direct', 'Direkt') :
                            _vm.$translate('no', 'Nej')}}):_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('arrangement_fee', 'Uppläggningsavgift'),"value":_vm.model.setupFeeString}}),_vm._v(" "),_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('without_uc', 'Utan UC'),"value":_vm.$formatBoolean(!_vm.model.takesUC),"tooltip":_vm.$translate('uses', 'Använder') + ' ' + _vm.$getCreditCheckString(_vm.model.creditCheck)}}),_vm._v(" "),_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('payment_remarks', 'Betalningsanmärkning'),"value":_vm.model.acceptsPaymentRemarks ?
                            _vm.$translate('accepted', 'Accepteras') :
                            _vm.translate('not_accepted', 'Accepteras inte'),"tooltip":_vm.model.acceptsPaymentRemarks && _vm.model.paymentRemarksMaxCount ?
                            ("Max " + (_vm.model.paymentRemarksMaxCount) + " st") :
                            null}})]:(_vm.$isNorway())?[_c('table-row',{staticClass:"border-1",attrs:{"title":"Bank-ID","value":_vm.$formatBoolean(_vm.model.hasBankId)}}),_vm._v(" "),_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('payment_remarks', 'Betalningsanmärkning'),"value":_vm.model.acceptsPaymentRemarks ? 
                            _vm.$translate('accepted', 'Accepteras') :
                            _vm.$translate('not_accepted', 'Accepteras inte')}})]:(_vm.$isEstonia())?[_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('arrangement_fee', 'Uppläggningsavgift'),"value":_vm.model.getEstonianSetupFeeString()}})]:(_vm.$isSpain())?[(_vm.model.isBroker)?_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('preamble_effective_interest', 'Effektiv ränta'),"value":_vm.model.effectiveInterestString,"tooltip":_vm.$translate('spain_interest_tooltip_on_broker')}}):_vm._e(),_vm._v(" "),_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('spain_loan_type', 'Loan type'),"value":_vm.model.isBroker ?
                            _vm.$translate('spain_loan_type_broker', 'Broker') :
                            _vm.$translate('spain_loan_type_lender', 'Lender')}}),_vm._v(" "),_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('no_salary', 'Utan inkomst från lön'),"value":_vm.$formatBoolean(_vm.model.acceptsNoSalary)}}),_vm._v(" "),_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('with_asnef', 'With ASNEF'),"value":_vm.$formatBoolean(_vm.model.acceptsASNEF)}})]:_vm._e(),_vm._v(" "),_c('table-row',{staticClass:"border-1",attrs:{"title":_vm.$translate('min_age', 'Åldersgräns'),"value":_vm.model.minAge + ' ' + _vm.$translate('years', 'år')}})],2),_vm._v(" "),_c('div',{staticClass:"px-3"},[_c('out-link',{staticClass:"btn btn-sm btn-primary my-3 w-100",attrs:{"document":_vm.lender}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }