<template>
    <div class="card col">
        <div class="card-body">
            <form>
                <amount-selector 
                    :amount="amount" 
                    :min-value="amountRange.minValue"
                    :max-value="amountRange.maxValue"
                    :increment="amountRange.increment"
                    @changed="(amount) => newAmount = amount" 
                />
                <duration-selector 
                    :duration="duration" 
                    @changed="(duration) => newDuration = duration" 
                />
                <interest-rate-selector 
                    :interest-rate="interestRate" 
                    :interest-key-name="interestKeyName"
                    :interest-title="interestTitle"
                    @changed="(interestRate) => newInterestRate = interestRate" 
                />
                <div v-if="$isSweden()" class="row">
                    <div class="col-md-6 col-12 mb-3">
                        <label class="form-label">{{ $translate('calculator_arrangement_fee', 'Uppläggningsavgift (Max 1000 kr)') }}</label>
                        <input 
                            id="amount" 
                            v-model.number="newArrangementFee" 
                            type="number" 
                            class="form-control form-control-lg" 
                            name="arrangementFee" 
                            placeholder="0"
                            v-on="checkArrangementFee()"
                        >
                    </div>
                    <div class="col-md-6 col-12 mb-3">
                        <label class="form-label">{{ $translate('calculator_invoice_fee', 'Aviavgift (Max 50 kr / månad)') }}</label>
                        <input 
                            id="amount" 
                            v-model.number="newInvoiceFee" 
                            type="number" 
                            class="form-control form-control-lg" 
                            name="invoiceFee" 
                            placeholder="0"
                            v-on="checkInvoiceFee()"
                        >
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import AmountSelector from '@/components/calculator/AmountSelector.vue';
import DurationSelector from '@/components/calculator/DurationSelector.vue';
import InterestRateSelector from '@/components/calculator/InterestRateSelector.vue';
export default {
    components: {
        AmountSelector,
        DurationSelector,
        InterestRateSelector
    },
    props: {
        amount: {
            type: Number,
            required: true
        },
        duration: {
            type: Number,
            required: true
        },
        interestRate: {
            type: Number,
            required: true
        },
        arrangementFee: {
            type: Number,
            required: true
        },
        invoiceFee: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            newAmount: this.amount,
            newDuration: this.duration,
            newInterestRate: this.interestRate,
            newArrangementFee: this.arrangementFee === 0 ? null : this.arrangementFee,
            newInvoiceFee: this.invoiceFee === 0 ? null : this.invoiceFee,

            interestKeyName: this.$isSpain() ? 'preamble_effective_interest' : 'interest', 
            interestTitle: this.$isSpain() ? 'Effektiv ränta' : 'Ränta',
        };
    },
    computed: {
        values() {
            return {
                amount: this.newAmount,
                duration: this.newDuration,
                interestRate: this.newInterestRate,
                arrangementFee: Number(this.newArrangementFee),
                invoiceFee: Number(this.newInvoiceFee)
            };
        },
        amountRange() {
            if (this.$isSpain()) {
                return {
                    minValue: 50,
                    maxValue: 60000,
                    increment: 50
                };
            }
            return {
                minValue: 1000,
                maxValue: 600000,
                increment: 1000
            };
        }
    },
    watch: {
        values() {
            this.$emit('changed', this.values);
        }
    },
    methods: {
        checkArrangementFee() {
            if (this.newArrangementFee > 1000) {
                this.newArrangementFee = 1000;
            }
        },
        checkInvoiceFee() {
            if (this.newInvoiceFee > 50) {
                this.newInvoiceFee = 50;
            }
        },
    }
};
</script>