<template>
    <div class="container mb-5">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <ul class="list-unstyled text-dark d-flex flex-column gap-4 mb-0">
                    <li
                        v-for="(lender, index) in lenders"
                        :key="index"
                        class="border rounded p-4"
                    >
                        <div class="row gy-3">
                            <div class="col-12 col-md-3 mb-3 mb-md-0 d-flex justify-content-center align-items-center">
                                <nuxt-link :to="$prismic.linkResolver(lender.document)" class=" max-wpx-150">
                                    <PrismicImage
                                        :img="lender.document.data.logo"
                                        :alt="lender.document.data.title"
                                        w="150"
                                        class="w-100 object-fit-contain"
                                    />
                                </nuxt-link>
                            </div>
                            <div class="col-12 col-md-3 d-flex flex-md-column justify-content-center align-items-center gap-3 gap-md-0">
                                <span>{{ $translate('interest', 'Ränta') }}</span>
                                <span class="fw-bold">{{ lender.model.interestString }}</span>
                            </div>
                            <div class="col-12 col-md-3 d-flex flex-md-column justify-content-center align-items-center gap-3 gap-md-0">
                                <span>{{ $translate('max_loan_amount', 'Max belopp') }}</span>
                                <span class="fw-bold">{{ lender.model.maxAmountString }}</span>
                            </div>
                            <div class="col-12 col-md-3 d-flex align-items-center">
                                <out-link
                                    class="btn btn-primary w-100" 
                                    :document="lender.document" 
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        lenders() {
            return this.slice.items
                .filter(item => item?.lender?.id)
                .map(item => {
                    const document = this.$store.state.lenders[item.lender.id];
                    const model = new this.$models.Loan(document);
                    return { document: document, model: model};
                });
        }   
    },
};
</script>