<template>
    <div class="container">
        <h2 class="mt-5">{{ $translate('sitemap_pages', 'Sidor') }}</h2>
        <nuxt-link
            v-for="document in orderByTitle(pages)"
            :key="document.id"
            :to="$prismic.linkResolver(document)"
            class="d-block mb-1"
        >
            {{ document.data.title || $translate('sitemap_not_set', 'Not set') }}
        </nuxt-link>
        <h2 class="mt-5">{{ $translate('sitemap_credit_card', 'Kreditkort') }}</h2>
        <nuxt-link
            v-for="document in orderByTitle($store.state.cards)"
            :key="document.id"
            :to="$prismic.linkResolver(document)"
            class="d-block mb-1"
        >
            {{ document.data.title || translate($store, 'sitemap_not_set', 'Not set') }}
        </nuxt-link>
        <h2 class="mt-5">{{ $translate('sitemap_private_loan', 'Privatlån') }}</h2>
        <nuxt-link
            v-for="document in orderByTitle($store.state.lenders)"
            :key="document.id"
            :to="$prismic.linkResolver(document)"
            class="d-block mb-1"
        >
            {{ document.data.title || $translate('sitemap_not_set', 'Not set') }}
        </nuxt-link>
        <h2 class="mt-5">{{ $translate('sitemap_corporate_loan', 'Företagslån') }}</h2>
        <nuxt-link
            v-for="document in orderByTitle($store.state.corporateLenders)"
            :key="document.id"
            :to="$prismic.linkResolver(document)"
            class="d-block mb-1"
        >
            {{ document.data.title || $translate('sitemap_not_set', 'Not set') }}
        </nuxt-link>
    </div>
</template>
<script>
import { orderBy } from 'lodash';
import { queryAll } from '@swegaming-ab/nuxtjs-helpers';
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            pages: []
        };
    },
    async fetch() {
        this.pages = Object.values(await queryAll(
            this.$prismic.api,
            this.$prismic.predicates.at('document.type', 'page'),
            { lang: process.env.PRISMIC_LOCALE }
        )).filter(page => ! page.data.is_landing_page);
    },
    methods: {
        orderByTitle(documents) {
            return orderBy(documents, [document => document.data.title], ['asc']);
        },
    }
};
</script>