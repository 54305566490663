<template>
    <div class="card col-12 p-5">
        <div class="d-flex flex-row align-self-start align-items-center form-check form-switch">
            <input id="tableSwitch" class="form-check-input" type="checkbox" @click="toggleTable()">
            <label class="form-check-label" for="tableSwitch">{{ $translate('calculator_show_details', 'Visa detaljer') }}</label>
        </div>
        <div class="row">
            <div v-show="showTable" id="detailsTable" class="mt-5 table-responsive">
                <table class=" table-light w-100">
                    <thead>
                        <tr class="bg-light">
                            <th class="p-3">{{ $translate('year', 'År') }}</th>
                            <th class="p-3">{{ $translate('calculator_details_header_time_of_payment', 'Betalningstillfälle') }}</th>
                            <th class="p-3">{{ $translate('calculator_details_header_debt_before_amortization', 'Skuld före amortering') }}</th>
                            <th class="p-3">{{ $translate('calculator_details_header_amortization', 'Amortering') }}</th>
                            <th class="p-3">{{ $isSpain() ? $translate('preamble_effective_interest', 'Effektiv ränta') : $translate('interest', 'Ränta') }}</th>
                            <th v-if="$isSweden()" class="p-3">{{ $translate('calculator_details_header_fees', 'Avgifter') }}</th>
                            <th class="p-3">{{ $translate('calculator_details_header_to_pay', 'Att betala') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="monthNumber in duration" :key="monthNumber">
                            <td class="p-3 border"> {{ getMonthAndYear(monthNumber + currentMonth) }} </td>
                            <td class="p-3 border"> {{ monthNumber }} </td>
                            <td class="p-3 border"> {{ $format.currency(getDebtBeforeAmortization(monthNumber), 2) }} </td>
                            <td class="p-3 border"> {{ $format.currency(getAmortization(), 2) }} </td>
                            <td class="p-3 border"> {{ $format.currency(getInterest(), 2) }} </td>
                            <td v-if="$isSweden()" class="p-3 border"> {{ $format.currency(getFee(monthNumber), 2) }} </td>
                            <td class="p-3 border"> {{ $format.currency(getAmountToPay(monthNumber), 2) }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        amount: {
            type: Number,
            required: true
        },
        duration: {
            type: Number,
            required: true
        },
        interestRate: {
            type: Number,
            required: true
        },
        arrangementFee: {
            type: Number,
            required: true
        },
        invoiceFee: {
            type: Number,
            required: true
        }
    },
    data() {
        this.debtBeforeAmortization = 10000;
        this.currentYear = new Date().getFullYear();
        this.incrementingYear = this.currentYear;
        return {
            showTable: false,
            currentMonth: new Date().getMonth(),
            monthlyInterestRate: (this.interestRate / 100) / 12
        };
    },
    computed: {
        annuityMonthlyPayment() {
            return (this.monthlyInterestRate * this.amount) / (1 - (1 + this.monthlyInterestRate) ** -this.duration);
        }
    },
    methods: {
        toggleTable() {
            this.showTable = ! this.showTable;
        },
        getMonthAndYear(monthNumber) {
            const months = [this.$translate('january', 'Jan'), 
                this.$translate('february', 'Feb'), 
                this.$translate('march', 'Mar'),
                this.$translate('april', 'Apr'),
                this.$translate('may', 'Maj'), 
                this.$translate('june', 'Jun'), 
                this.$translate('july', 'Jul'),
                this.$translate('august', 'Aug'),
                this.$translate('september', 'Sep'),
                this.$translate('october', 'Okt'), 
                this.$translate('november', 'Nov'), 
                this.$translate('december', 'Dec')];

            if (monthNumber === this.currentMonth + 1 || monthNumber % months.length === 0) {
                if (monthNumber === this.currentMonth + 1) {
                    this.incrementingYear = this.currentYear;
                }
                else if (monthNumber % months.length === 0) {
                    this.incrementingYear++;
                }
                return months[monthNumber % months.length] + ', ' + this.incrementingYear;
            }
            return months[monthNumber % months.length];
        },
        getDebtBeforeAmortization(monthNumber) {
            if (monthNumber === 1) {
                this.debtBeforeAmortization = this.amount;
                return this.amount;
            }
            this.debtBeforeAmortization -= this.getAmortization();
            return this.debtBeforeAmortization;
        },
        getInterest() {
            return this.monthlyInterestRate * this.debtBeforeAmortization;
        },
        getFee(monthNumber) {
            if (monthNumber === 1) {
                return this.arrangementFee + this.invoiceFee;
            }
            return this.invoiceFee;
        },
        getAmountToPay(monthNumber) {
            if (this.$isSweden()) {
                return this.getAmortization() + this.getInterest() + this.getFee(monthNumber);
            }
            return this.annuityMonthlyPayment;
        },
        getAmortization() {
            if (this.$isSweden()) {
                return this.amount / this.duration;
            }
            return this.annuityMonthlyPayment - this.getInterest();
        },
    }
};
</script>