<template>
    <div class="mb-3">
        <div class="d-flex justify-content-between">
            <label class="form-label fs-5">{{ $translate('loan_duration', 'Löptid') }}</label>
            <label v-if="newDuration === 1" class="form-label fs-5">{{ newDuration }} {{ $translate('month', 'månad') }}</label>
            <label v-else class="form-label fs-5">{{ newDuration }} {{ $translate('months', 'månader') }}</label>
        </div>
        <input id="amount" v-model.number="newDuration" type="range" class="form-range form-input-lg" name="amount" min="1" max="180" step="1">
        <div class="d-flex justify-content-between">
            <label class="form-label text-secondary">1 {{ $translate('month', 'månad') }}</label>
            <label class="form-label text-secondary">{{ $translate('calculator_loan_max_duration', '180 månader (15 år)') }}</label>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        duration: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            newDuration: this.duration
        };
    },
    watch: {
        newDuration: function() {
            this.$emit('changed', this.newDuration);
        }
    }
};
</script>