<template>
    <div class="mb-3">
        <div class="d-flex justify-content-between">
            <label class="form-label fs-5">{{ $translate('calculator_loan_amount', 'Lånebelopp') }}</label>
            <label class="form-label fs-5">{{ $format.currency(newAmount) }}</label>
        </div>
        <input 
            id="amount" 
            v-model.number="newAmount" 
            type="range" 
            class="form-range form-input-lg" 
            name="amount" 
            :min="minValue" 
            :max="maxValue" 
            :step="increment"
        >
        <div class="d-flex justify-content-between">
            <label class="form-label text-secondary">{{ $format.currency(minValue) }}</label>
            <label class="form-label text-secondary">{{ $format.currency(maxValue) }}</label>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        amount: {
            type: Number,
            required: true
        },
        minValue: {
            type: Number,
            required: false,
            default: 1000
        },
        maxValue: {
            type: Number,
            required: false,
            default: 600000
        },
        increment: {
            type: Number,
            required: false,
            default: 1000
        }
    },
    data() {
        return {
            newAmount: this.amount
        };
    },
    watch: {
        newAmount: function() {
            this.$emit('changed', this.newAmount);
        }
    }
};
</script>