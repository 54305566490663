<template>
    <div class="d-flex flex-column gap-4 pb-4 border-bottom border-2">
        <nuxt-link 
            :to="$prismic.linkResolver(item.lender)" 
            class="d-flex justify-content-between align-items-center text-dark text-primary-hover"
        >
            <div>
                <span class="h3 text-dark">{{ index + 1 }}.</span>
                <h3 class="mb-0 text-reset d-inline">{{ item.h3 }}</h3>
            </div>
            <prismic-image 
                :img="item.lender.data.logo"
                :alt="item.lender.data.title"
                w="100"
            />
        </nuxt-link>
        <div 
            v-if="$validateText(item.teaser)"
            class="rich-text text-dark"
            v-html="$prismic.asHtml(item.teaser)"
        />
        <table class="table text-dark shadow-sm">
            <tbody>
                <tr 
                    v-for="(tableRowData, rowIndex) in item.tableData"
                    :key="rowIndex"
                    :class="{'bg-soft-secondary': rowIndex % 2 === 1}"
                >
                    <th class="fw-bold text-dark w-50 fs-6 fs-md-5">{{ tableRowData.title }}</th>
                    <td class="text-dark w-50 border-start border-1 fs-6 fs-md-5">
                        {{ tableRowData.value }}
                        <i 
                            v-if="tableRowData.showTooltip" 
                            v-tooltip="tableRowData.tooltipData" 
                            class="bi bi-info-circle-fill ms-1 text-info"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
        <out-link
            class="btn btn-primary rounded fw-bold col-12 col-lg-3"
            :document="item.lender"
            :text="$translate('cta_to_application', 'Till ansökan')"
        />
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    }
};
</script>