<template>
    <div :id="$formatId(slice.id)" class="container">
        <h2 v-if="h2.length > 0" class="mb-5">{{ h2.replace('%count%', lendersCount) }}</h2>

        <filter-form 
            v-if="slice.primary.include_filter_box" 
            :filter-data="userFilter" 
            :list-length="listToShow.length" 
            :active-filters-length="activeFilters.length" 
            :unfiltered-list-length="listToFilter.length"
        />

        <div class="row">
            <lender-card 
                v-for="(lender, index) in topList" 
                :key="lender.id" 
                :lender-id="lender.id" 
                :position="index + 1"
            />
        </div>
        <div v-show="showAll" class="row">
            <lender-card 
                v-for="(lender, index) in notShownList" 
                :key="lender.id" 
                :lender-id="lender.id"
                :position="topList.length + index + 1"
            />
        </div>
        <CommercialDisclaimer />
        <div 
            v-show="!showAll && notShownList.length > 0" 
            class="col-lg-12 text-sm text-dark"
        >
            <div class="text-center mt-4 mb-4">
                <a class="btn btn-md btn-secondary fw-bold" @click="showAllLenders()">
                    {{ $translate('show_all_lenders', 'Visa alla långivare') }} <i class="bi bi-arrow-down-circle-fill ms-2" />
                </a>
            </div>
        </div>
        <structured-data 
            v-if="slice.primary.include_statistics_table || slice.primary.include_comparisons_table"
            :slice="slice"
            :list="listToShow" 
        />
        <loan-wizard-intro 
            v-if="slice.primary.include_loan_wizard_intro"
            :teaser="slice.primary.loan_wizard_intro_teaser"
            two-columns
            class="my-5"
        />
    </div>
</template>
<script>
import LenderCard from '@/components/cards/LenderCard.vue';
import CommercialDisclaimer from '@/components/CommercialDisclaimer.vue';
import FilterForm from '@/components/filter/FilterForm.vue';
import StructuredData from '@/components/structuredData/index.vue';
import LoanWizardIntro from '@/components/LoanWizardIntro.vue';

import FilterLogic from '@/components/filter/FilterLogic.js'; 

export default {
    components: {
        LenderCard,
        CommercialDisclaimer,
        FilterForm,
        StructuredData,
        LoanWizardIntro
    },
    mixins: [FilterLogic],
    data() {
        return {
            showAll: false,
            lendersToShow: 12,
        };
    },

    computed: {
        listToShow() {
            if (this.slice.primary.include_filter_box) {
                return this.filteredList;
            }
            return this.list;
        },
        topList() {

            if (this.count) {
                return this.listToShow.slice(0, this.count);
            }

            return this.listToShow.slice(0, this.lendersToShow);
        },
        notShownList() {

            if (this.count) {
                return [];
            }

            return this.listToShow.slice(this.lendersToShow);
        },
        lendersCount() {
            if (this.count) {
                return this.count;
            }
            
            return this.list.length;
        }
    },
    methods: {
        showAllLenders() {
            this.showAll = true;
        }
    },
};
</script>