<template>
    <div class="container mt-4 mb-4">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <ul class="step">
                    <li v-for="(step, index) in slice.items" :key="index" class="step-item">
                        <div class="step-content-wrapper">
                            <span v-if="isOrderedList" class="step-icon" :class="stepIconClass">
                                {{ index + 1 }}
                            </span>
                            <span v-else class="step-icon" :class="stepIconClass">
                                <i :class="icon" />
                            </span>
                            <div class="step-content">
                                <h3 class="step-title">{{ step.item_title }}</h3>
                                <div class="text-dark rich-text" v-html="$prismic.asHtml(step.item_content)" />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        stepIconClass() {
            return {
                'primary': 'step-icon-primary',
                'secondary': 'step-icon-secondary',
                'dark': 'step-icon-dark',
                'light': 'step-icon-light',
                'success': 'step-icon-success',
                'danger': 'step-icon-danger',
                'warning': 'step-icon-warning',
                'info': 'step-icon-info'
            }[this.slice.primary.style] || 'step-icon-soft-primary';
        },
        icon() {
            return {
                'danger': 'bi bi-dash-lg',
                'warning': 'bi bi-dash-lg',
            }[this.slice.primary.style] || 'bi bi-check-lg';
        },
        isOrderedList() {
            return this.slice.primary.ordered_list;
        }
    }
};
</script>