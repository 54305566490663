<template>
    <div v-if="items.length" class="container content-space-1">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <div class="border-bottom border-2 content-space-b-1">
                    <div class="mb-4">
                        <h2 v-if="$validateText(slice.primary.h2)">
                            {{ slice.primary.h2 }}
                        </h2>
                        <div 
                            v-if="$validateText(slice.primary.teaser)"
                            class="rich-text text-dark" 
                            v-html="$prismic.asHtml(slice.primary.teaser)"
                        />
                    </div>
                    <top-list 
                        v-if="slice.primary.show_toplist"
                        :items="items"
                    /> 
                </div>
                <big-list-item 
                    v-for="(item, index) in items" 
                    :key="index"
                    :item="item"
                    :index="index"
                    class="d-flex flex-column gap-4 content-space-t-1"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { pick, capitalize } from 'lodash';
import TopList from '@/components/TopList.vue';
import BigListItem from '@/components/BigListItem.vue';
export default {
    components: {
        TopList,
        BigListItem
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        items() {
            const items = [];
            
            this.slice.items.filter(item => item.lender.id)
                .forEach(item => {
                    const storeKey = item.lender.type === 'lender_corporate' ? 'corporateLenders' : 'lenders';
                    const modelKey = item.lender.type === 'lender_corporate' ? 'CorporateLoan' : 'Loan';
                    const lender = this.$store.state[storeKey][item.lender.id];
                    items.push({
                        lender: lender,
                        h3: this.$validateText(item.h3) ? item.h3 : lender.data.title,
                        teaser: item.teaser,
                        tableData: Object.values(this.getTableData(lender)),
                        // eslint-disable-next-line camelcase
                        toplist_text: item.toplist_text,
                        model: new this.$models[modelKey](lender)
                    });
                });
            return items;
        },
    },
    methods: {
        getTableData(lender) {
            if (lender.type === 'lender_corporate') {
                const model = new this.$models.CorporateLoan(lender);

                return this.allTableData(
                    [
                        'loanAmountLowest',
                        'loanAmountHighest',
                        'requiresCollateral',
                        'paymentRemarksCompany',
                        'paymentRemarksRepersentative',
                        'takesUCCompany',
                        'takesUCRepersentative',
                    ],
                    model
                );
            }

            const model = new this.$models.Loan(lender);
            if (this.$isSweden()) {
                return this.swedenTableData(model);
            }
            if (this.$isNorway()) {
                return this.norwayTableData(model);
            }
            if (this.$isSpain()) {
                return this.spainTableData(model);
            }
            if (this.$isEstonia()) {
                return this.estonianTableData(model);
            }
            if (this.$isDenmark()) {
                return this.denmarkTableData(model);
            }
            
        },
        swedenTableData(model) {
            if (model.isHighCostCredit) {
                return this.allTableData(
                    [
                        'loanAmount',
                        'interest',
                        'duration',
                        'creditCheck',
                        'paymentRemarks',
                        'directPayments',
                    ],
                    model
                );
            }
            if (model.isBroker) {
                return this.allTableData(
                    [
                        'loanAmount',
                        'interest',
                        'duration',
                        'paymentRemarks',
                        'connectedBanks',
                    ],
                    model
                );
            }
            return this.allTableData(
                [
                    'loanAmount',
                    'interest',
                    'duration',
                    'paymentRemarks',
                    'arrangementFee',
                ],
                model
            );
        },
        norwayTableData(model) {
            if (model.isHighCostCredit) {
                return this.allTableData(
                    [
                        'loanAmount',
                        'interest',
                        'duration',
                        'paymentRemarks',
                        'ageRequirement',
                    ],
                    model
                );
            }
            if (model.isBroker) {
                return this.allTableData(
                    [
                        'loanAmount',
                        'interest',
                        'duration',
                        'paymentRemarks',
                        'ageRequirement',
                        'connectedBanks',
                    ],
                    model
                );
            }
            return this.allTableData(
                [
                    'loanAmount',
                    'interest',
                    'duration',
                    'paymentRemarks',
                    'ageRequirement',
                    'arrangementFee',
                ],
                model
            );
        },
        spainTableData(model) {
            return this.allTableData(
                [
                    'loanAmount',
                    'duration',
                    'effectiveInterest',
                    'noSalary',
                    'acceptsASNEF',
                    'isBroker',
                    'ageRequirement',
                ],
                model
            );
        },
        estonianTableData(model) {
            return this.allTableData(
                [
                    'loanAmount',
                    'duration',
                    'interest',
                    'effectiveInterest',
                    'arrangementFee',
                    'ageRequirement',
                ],
                model
            );
        },
        denmarkTableData(model) {
            if (model.isHighCostCredit) {
                return this.allTableData(
                    [
                        'loanAmount',
                        'interest',
                        'duration',
                        'creditCheck',
                        'paymentRemarks',
                        'directPayments',
                    ],
                    model
                );
            }
            if (model.isBroker) {
                return this.allTableData(
                    [
                        'loanAmount',
                        'interest',
                        'duration',
                        'paymentRemarks',
                        'connectedBanks',
                    ],
                    model
                );
            }
            return this.allTableData(
                [
                    'loanAmount',
                    'interest',
                    'duration',
                    'paymentRemarks',
                    'arrangementFee',
                ],
                model
            );
        },
        allTableData(keys, model) {
            const allTableData = {
                loanAmount: {
                    title: this.$translate('loan_amount', 'Belopp'),
                    value: model.amountString,
                },
                interest: {
                    title: this.$translate('interest', 'Ränta'),
                    value: model.interestString,
                },
                duration: {
                    title: this.$translate('loan_duration', 'Löptid'),
                    value: this.$isSpain() ? 
                        this.$getSpanishLoanDuration(model, this.$translate('month_short', 'mån'),
                            this.$translate('year_short', 'år')) :
                        model.getDurationString(this.$translate('month_short', 'mån'),
                            this.$translate('year_short', 'år')),
                },
                creditCheck: {
                    title: this.$translate('credit_check', 'Kreditupplysning'),
                    value: capitalize(model.creditCheck),
                },
                paymentRemarks: {
                    title: this.$translate('payment_remarks', 'Betalningsanmärkningar'),
                    value: model.acceptsPaymentRemarks 
                        ? this.$translate('accepted', 'Accepteras') 
                        : this.$translate('not_accepted', 'Accepteras inte'),
                },
                directPayments: {
                    title: this.$translate('direct_payout', 'Direktutbetalning'),
                    value: model.hasDirectPayments24Hours 
                        ? this.$translate('twentyfour_seven', 'Dygnet runt') 
                        : this.$formatBoolean(model.hasDirectPayments),
                    showTooltip: this.showDirectPaymentsExplanation(model),
                    tooltipData: this.directPaymentsExplanation(model)
                },
                arrangementFee: {
                    title: this.$translate('arrangement_fee', 'Uppläggningsavgift'),
                    value: this.$isEstonia() ?
                        model.getEstonianSetupFeeString() :
                        model.setupFeeString,
                },
                connectedBanks: {
                    title: this.$translate('connected_banks', 'Anslutna långivare'),
                    value: model.connectedBanks,
                },
                ageRequirement: {
                    title: this.$translate('min_age', 'Åldersgräns'),
                    value: `${model.minAge} ${this.$translate('years', 'år')}`,
                },
                loanAmountLowest: {
                    title: this.$translate('min_amount', 'Lägsta lånebelopp'),
                    value: model.minAmountString,
                },
                loanAmountHighest: {
                    title: this.$translate('max_amount', 'Högsta lånebelopp'),
                    value: model.maxAmountString,
                },
                requiresCollateral: {
                    title: this.$translate('demands_security', 'Kräver säkerhet'),
                    value: this.$formatBoolean(model.requiresCollateral),
                },
                paymentRemarksCompany: {
                    title: this.$translate('payment_remarks_company', 'Betalningsanmärkningar på företaget'),
                    value: model.acceptsPaymentRemarks?.company 
                        ? this.$translate('accepted', 'Accepteras') 
                        : this.$translate('not_accepted', 'Accepteras inte'),
                },
                paymentRemarksRepersentative: {
                    title: this.$translate('payment_remarks_company_representative', 'Betalningsanmärkningar på firmatecknaren'),
                    value: model.acceptsPaymentRemarks?.repersentative 
                        ? this.$translate('accepted', 'Accepteras') 
                        : this.$translate('not_accepted', 'Accepteras inte'),
                },
                takesUCCompany: {
                    title: this.$translate('takes_uc_on_company', 'Tar uc på företaget'),
                    value: this.$formatBoolean(model.takesUC?.company),
                },
                takesUCRepersentative: {
                    title: this.$translate('takes_uc_on_company_representative', 'Tar uc på firmatecknaren'),
                    value: this.$formatBoolean(model.takesUC?.repersentative),
                },
                effectiveInterest: {
                    title: this.$translate('preamble_effective_interest', 'Effektiv ränta'),
                    value: model.effectiveInterestString
                },
                noSalary: {
                    title: this.$translate('no_salary', 'Utan inkomst från lön'),
                    value: this.$formatBoolean(model.acceptsASNEF),
                },
                acceptsASNEF: {
                    title: this.$translate('with_asnef', 'With ASNEF'),
                    value: this.$formatBoolean(model.acceptsASNEF),
                },
                isBroker: {
                    title: this.$translate('is_broker', 'Är låneförmedlare?'),
                    value: this.$formatBoolean(model.isBroker),
                },

            };
            return pick(allTableData, keys);
        },
        showDirectPaymentsExplanation(model) {
            const banks = [
                'Nordea', 'DanskeBank', 'Handelsbanken', 'SEB', 'Swedbank'
            ];

            for (let i = 0; i < banks.length; i++) {
                if (model[`hasDirectPaymentTo${banks[i]}`]) {
                    return true;
                }
            }

            return false;
        },
        directPaymentsExplanation(model) {
            const banks = [
                'Nordea', 'DanskeBank', 'Handelsbanken', 'SEB', 'Swedbank'
            ];

            let hasDirectPaymentTo = [];
            banks.forEach(bank => {
                if (model[`hasDirectPaymentTo${bank}`]) {
                    hasDirectPaymentTo.push(bank);
                }
            });
            return this.$translate('direct_payout_to_bank', 'Direktutbetalning till {bank}')
                .replace('{bank}', hasDirectPaymentTo.join(', '));
        },
    }
};
</script>