<template>
    <div class="container content-space-1">
        <div 
            v-if="$validateText(slice.primary.header)"
            class="align-self-start"
            v-html="$prismic.asHtml(slice.primary.header)"
        />
        <div class="row">
            <input-form 
                :amount="amount" 
                :duration="duration" 
                :interest-rate="interestRate" 
                :arrangement-fee="arrangementFee" 
                :invoice-fee="invoiceFee" 
                @changed="updateValues" 
            />
            <overview 
                :amount="amount" 
                :duration="duration" 
                :interest-rate="interestRate" 
                :setup-fee="arrangementFee" 
                :invoice-fee="invoiceFee" 
            />
        </div>
        <div class="row mt-5">
            <details-table 
                :amount="amount" 
                :duration="duration" 
                :interest-rate="interestRate" 
                :arrangement-fee="arrangementFee" 
                :invoice-fee="invoiceFee" 
            />
        </div>
    </div>
</template>
<script>
import InputForm from '@/components/calculator/InputForm.vue';
import Overview from '@/components/calculator/Overview.vue';
import DetailsTable from '@/components/calculator/DetailsTable.vue';
export default {
    components: {
        InputForm,
        Overview,
        DetailsTable
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            title: 'Calculator',
            amount: 10000,
            duration: 10,
            interestRate: 12,
            arrangementFee: 0,
            invoiceFee: 0
        };
    },
    methods: {
        updateValues(allValues) {
            this.amount = allValues.amount;
            this.duration = allValues.duration;
            this.interestRate = allValues.interestRate;
            this.arrangementFee = allValues.arrangementFee;
            this.invoiceFee = allValues.invoiceFee;
        }
    }
};
</script>